import React, { createContext, useContext } from 'react';
import { useCookies } from 'react-cookie';

export const LanguageContext = createContext();

export function LanguageProvider({ children }) {
    const [cookies, setCookie] = useCookies(['language']);

    if (!cookies['language']) {
        setCookie('language', 'pl', { path: '/', expires: new Date(Date.now() + 1 * 60 * 60 * 24 * 365) });
    }

    let value = {
        language: cookies['language'],
        setLanguage: (code) => setCookie('language', code, { path: '/', expires: new Date(Date.now() + 1 * 60 * 60 * 24 * 365) }),
        languages: ["pl", "en"],
        getFormattedLanguage: getFormattedLanguage,
        getText: getText,
    }

    return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;

    function getFormattedLanguage(code) {
        let formatted = {
            "en": "EN 🇬🇧",
            "pl": "PL 🇵🇱",
        }
        return formatted[code];
    }

    function getText(key) {
        return text[value.language][key];
    }
}

export function useLanguage() {
    return useContext(LanguageContext);
}

const text = {
    "en": {
        "knowledge-sea": "Knowledge Sea",

        "scientific-paper-ai": "Scientific Paper AI",
        "scientific-paper-ai-description": "AI tools for understanding scientific papers. This includes knowledge graph generation and chatbot integration.",
        "banking-ai": "Banking AI",
        "banking-ai-description": "AI helper for banking. Trained on a corpus of legislation and publically accessible banking documents.",
        "infrastructure-ai": "Infrastructure AI",
        "infrastructure-ai-description": "AI helper for energy infrastructure. Trained on a corpus of safety and regional report documents.",
        "bps-ai": "BPS AI",
        "bps-ai-description": "AI chatbot for BPS mobile manual.",
        "pzu-ai": "PZU AI",
        "pzu-ai-description": "AI chatbot for PZU insurance documents.",
        "enea-ai": "ENEA AI",
        "enea-ai-description": "AI chatbot for ENEA energy legislation documents.",

        "home": "Home",
        "science-ai": "Science AI",
        "not-found": "Not Found",

        "add-knowledge": "Add Knowledge",
        "query-knowledge": "Query Knowledge",
        "clear-knowledge": "Clear Knowledge",
        "query-knowledge-graph": "Query Knowledge Graph",
        "add-scientific-documents": "Add Scientific Documents",
        "search...": "Search...",
        "text-not-available": "Abstrct and full text are not available for this document due to licensing restrictioans.",
        "relevancy": "Relevancy",
        "artnum": "Article Number",
        "citedby-count": "Cited By Count",
        "coverDate": "Cover Date",
        "creator": "Creator",
        "pagecount": "Page Count",
        "pagefirst": "Page First",
        "pageRange": "Page Range",
        "publicationName": "Publication Name",
        "pubyear": "Publication Year",
        "volume": "Volume",

        "reference-data": "Reference Data",
        "sources-will-appear": "Sources will appear here as they are cited.",
        "question-redirection": "Start a chat by sending asking a question below",
        "ask-a-question": "Ask a question...",

        "infrastructure-ai-construction": "Infrastructure AI is under construction",

        "login": "Login",
        "logout": "Logout",
        "username": "Username",
        "password": "Password",
        "login-failed": "Login failed.",
        "login-success": "Login success.",
        "submit": "Submit",

        "paragraph": "Paragraph",

        "document-new-tab": "Click to open original document in new tab",
        "here": "here",

        "vm-unknown": "Querying LLM state...",
        "vm-stopped": "LLM machine is stopped. Sending startup request...",
        "vm-stopping": "LLM machine is stopping. Waiting for shutdown...",
        "vm-booting": "Booting LLM machine...",
        "vm-booted": "Booted LLM machine...",
        "vm-starting": "Starting LLM software...",
        "vm-ready": "Ready",
    },
    "pl": {
        "knowledge-sea": "Morze Wiedzy",

        "scientific-paper-ai": "AI Naukowych Artykułów",
        "scientific-paper-ai-description": "Narzędzia AI do zrozumienia naukowych artykułów. W tym generowanie grafów wiedzy i integracja z chatbotem.",
        "banking-ai": "Bankowość AI",
        "banking-ai-description": "AI pomocnik dla bankowości. Wytrenowany na korpusie ustawodawstwa i publicznie dostępnych dokumentów bankowych.",
        "infrastructure-ai": "Infrastruktura AI",
        "infrastructure-ai-description": "AI pomocnik dla infrastruktury energetycznej. Wytrenowany na korpusie dokumentów dotyczących bezpieczeństwa i regionalnych raportów.",
        "bps-ai": "BPS AI",
        "bps-ai-description": "AI chatbot dla BPS mobile manual.",
        "pzu-ai": "PZU AI",
        "pzu-ai-description": "AI chatbot dla dokumentów ubezpieczeniowych PZU.",
        "enea-ai": "ENEA AI",
        "enea-ai-description": "AI chatbot dla dokumentów legislacyjnych energetyki ENEA.",

        "home": "Strona główna",
        "science-ai": "AI Naukowych Artykułów",
        "not-found": "Nie znaleziono",

        "add-knowledge": "Dodaj Wiedzę",
        "query-knowledge": "Zapytaj o Wiedzę",
        "clear-knowledge": "Wyczyść Wiedzę",
        "query-knowledge-graph": "Zapytaj o Graf Wiedzy",
        "add-scientific-documents": "Dodaj Naukowe Dokumenty",
        "search...": "Szukaj...",
        "text-not-available": "Abstrakt i pełny tekst nie są dostępne dla tego dokumentu z powodu ograniczeń licencyjnych.",
        "relevancy": "Relewantność",
        "artnum": "Numer Artykułu",
        "citedby-count": "Liczba Cytowań",
        "coverDate": "Data Okładki",
        "creator": "Twórca",
        "pagecount": "Liczba Stron",
        "pagefirst": "Pierwsza Strona",
        "pageRange": "Zakres Stron",
        "publicationName": "Nazwa Publikacji",
        "pubyear": "Rok Publikacji",
        "volume": "Tom",

        "reference-data": "Dane Referencyjne",
        "sources-will-appear": "Źródła pojawią się tutaj w miarę cytowania.",
        "question-redirection": "Rozpocznij czat wysyłając pytanie poniżej",
        "ask-a-question": "Zadaj pytanie...",

        "infrastructure-ai-construction": "Infrastruktura AI jest w budowie",

        "login": "Zaloguj",
        "logout": "Wyloguj",
        "username": "Nazwa użytkownika",
        "password": "Hasło",
        "login-failed": "Logowanie nie powiodło się.",
        "login-success": "Logowanie powiodło się.",
        "submit": "Zatwierdź",

        "paragraph": "Akapit",

        "document-new-tab": "Kliknij aby otworzyć oryginalny dokument w nowej karcie",
        "here": "tutaj",

        "vm-unknown": "Pobieranie stanu LLM...",
        "vm-stopped": "Maszyna LLM jest zatrzymana. Wysyłanie żądania uruchomienia...",
        "vm-stopping": "Maszyna LLM jest zatrzymywana. Oczekiwanie na wyłączenie...",
        "vm-booting": "Uruchamianie maszyny LLM...",
        "vm-booted": "Uruchomiono maszynę LLM...",
        "vm-starting": "Uruchamianie oprogramowania LLM...",
        "vm-ready": "Gotowy",
    },
}
