import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import { useAuth } from "../context/Auth";
import { useLanguage } from "../context/Language";

function Login() {
    const getText = useLanguage().getText;
    const auth = useAuth();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [state, setState] = useState('initial');

    const submit = async e => {
        e.preventDefault();

        const user = {
            username: username,
            password: password
        };

        auth.login(user).then(() => {
            setState('success');
        })
            .catch(err => {
                setState('failed');
            });
    }

    return (
        <div className="App-content container">
            <div className='d-flex justify-content-center'>
                <Paper
                    elevation={2}
                    sx={{ p: '1.5em 1.5em', display: 'inline-flex', alignItems: 'center', marginTop: "50px"}}>
                    <form onSubmit={submit} style={{width: "300px"}}>
                        <h2>{getText('login')}</h2>
                        <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">{getText('username')}</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                onChange={e => setUsername(e.target.value)}></input>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleInputPassword1" className="form-label">{getText('password')}</label>
                            <input type="password" className="form-control" id="exampleInputPassword1"
                                onChange={e => setPassword(e.target.value)}>
                            </input>
                        </div>
                        <div className="mb-3">
                            {state === "failed" && <div className="alert alert-danger" role="alert">{getText('login-failed')}</div>}
                            {state === "success" && <div className="alert alert-success" role="alert">{getText('login-success')}</div>}
                        </div>
                        <div className='d-flex justify-content-end'>
                            <button type="submit" className="btn btn-primary">{getText('submit')}</button>
                        </div>
                    </form>
                </Paper>
            </div>
        </div>
    );
}

export default Login;