import React from "react";
import { useBackend } from "../context/Backend";

export default function BackendSelector() {
    const { backend, setBackend, backends, getFormattedBackend } = useBackend();

    return (
        <div className="nav-item dropdown me-5">
            <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {getFormattedBackend(backend)}
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
                {
                    backends.map((code) => {
                        return (
                            <li key={code}><button onClick={() => setBackend(code)} className="dropdown-item">{getFormattedBackend(code)}</button></li>
                        )
                    })
                }
            </ul>
        </div>
    )
}