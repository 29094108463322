import React from "react";
import { useLanguage } from "../context/Language";

export default function LanguageSelector() {
    const { language, setLanguage, languages, getFormattedLanguage } = useLanguage();

    return (
        <div className="nav-item dropdown me-5">
            <button className="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {getFormattedLanguage(language)}
            </button>
            <ul className="dropdown-menu dropdown-menu-end">
                {
                    languages.map((code) => {
                        return (
                            <li key={code}><button onClick={() => setLanguage(code)} className="dropdown-item">{getFormattedLanguage(code)}</button></li>
                        )
                    })
                }
            </ul>
        </div>
    )
}