import React from "react";
import { routes } from "../App";
import { useLocation, Link } from 'react-router-dom'
import { useAuth } from '../context/Auth';
import { useDomain } from "../context/Domain";
import { useLanguage } from "../context/Language";

export default function Dropdown() {
    const excludedPaths = ["/login", "*"]
    const isAuthenticated = useAuth().isAuthenticated;
    const isAffexy = useDomain().isAffexy;
    const isBps = useDomain().isBps;
    const isPzu = useDomain().isPzu;
    const isEnea = useDomain().isEnea;
    const getText = useLanguage().getText;

    const dropdownItems = routes[0].children.map((route) => {
        if (excludedPaths.includes(route.path)) {
            return null;
        }

        if (route.requireNavAuth && !isAuthenticated) {
            return null;
        }

        if (route.requireAffexyDomain && !isAffexy) {
            return null;
        }

        if (route.requireBpsDomain && !isBps) {
            return null;
        }

        if (route.requirePzuDomain && !isPzu) {
            return null;
        }

        if (route.requireEneaDomain && !isEnea) {
            return null;
        }

        return (
            <li key={route.name}>
                <Link
                    className="dropdown-item"
                    to={route.path}>{getText(route.name)}</Link>
            </li>
        )
    });

    const currentLocation = useLocation().pathname;
    const currentPageName = routes[0].children.filter((route) => {
        return route.path === currentLocation;
    })[0]?.name;

    return (<li className="nav-item dropdown">
        <button className="nav-link dropdown-toggle" href="#" id="dropdown07XL" data-bs-toggle="dropdown" aria-expanded="true">{getText(currentPageName)}</button>
        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown07XL" data-bs-popper="none">
            {dropdownItems}
        </ul>
    </li>)
}
