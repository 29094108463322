import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { useLanguage } from "../context/Language";

const UserBubble = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const getText = useLanguage().getText;

    let ui = {};
    if (auth.isAuthenticated) {
        ui = {
            icon: "bi bi-person-circle",
            loginOrOut: <><li><hr className="dropdown-divider" /></li><li><button className="dropdown-item" 
            onClick={() => {auth.logout(); navigate("/")}}>{getText('logout')}</button></li></>,
            username: <li><button className="dropdown-item">{auth.username || getText("username")}</button></li>,
        }
    } else {
        ui = {
            icon: "bi bi-person",
            loginOrOut: <li><button className="dropdown-item" onClick={() => {navigate("/login")}}>{getText('login')}</button></li>,
            username: <></>,
        }
    }

    return (
        <li className="nav-item dropdown">
            <button className="nav-link dropdown-toggle" href="#" id="dropdown08XL" data-bs-toggle="dropdown" aria-expanded="true"><i className={"bi " + ui.icon}></i></button>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown08XL" data-bs-popper="none">
                {ui.username}
                {ui.loginOrOut}
            </ul>
        </li>
    )
}

export default UserBubble;

