import { useEffect, useRef, useId } from 'react';
import axios from 'axios';

import * as React from 'react';
import { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import Skeleton from '@mui/material/Skeleton';
import { CircularProgress } from '@mui/material';
import { useAuth } from '../../context/Auth';

import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';
import { useLanguage } from '../../context/Language';

export default function ScienceAi() {
    const auth = useAuth();

    const controlPanels = [
        { id: 'offcanvasAddKnowledge', name: 'add-knowledge' },
        { id: 'offcanvasQueryKnowledge', name: 'query-knowledge' },
    ]

    const [graphData, setGraphData] = useState({ "nodes": [], "edges": [] });

    useEffect(() => {
        if (!auth.isAuthenticated) {
            return;
        }

        axios.get(`/api/kg/graph/`)
            .then(function (response) {
                setGraphData(response.data);
            });
    }, [auth.isAuthenticated]);

    return (
        <>
            <div className='full-height'>
                <ControlPanelButtons controlPanels={controlPanels} setGraphData={setGraphData} />
                <AddKnowledgeControlPanel controlPanel={controlPanels[0]} setGraphData={setGraphData} />
                <QueryKnowledgeControlPanel controlPanel={controlPanels[1]} />
                <KnowledgeGraph graphData={graphData} />
            </div>
        </>
    );
}

function ControlPanelButtons({ controlPanels, setGraphData }) {
    const getText = useLanguage().getText;

    function onClearKnowledge() {
        axios.delete(`/api/kg/graph/`)
            .then(function (response) {
                setGraphData({ "nodes": [], "edges": [] })
            });
    }

    return (
        <div className='position-relative'>
            <div className='position-absolute top-0 start-0' style={{ zIndex: 1 }}>
                {controlPanels.map((controlPanel) => (
                    <div className='m-3'>
                        <button className="btn btn-outline-secondary shadow" type="button" data-bs-toggle="offcanvas" data-bs-target={'#' + controlPanel.id} aria-controls={getText(controlPanel.name)}>
                            <ChevronLeftIcon /> {getText(controlPanel.name)}
                        </button>
                    </div>
                ))}
                <div className='m-3'>
                    <button className="btn btn-outline-danger shadow" type="button" aria-controls="clear knowledge" onClick={onClearKnowledge}>
                        {getText('clear-knowledge')}
                    </button>
                </div>
            </div>
        </div>
    )
}

function AddKnowledgeControlPanel({ controlPanel, setGraphData }) {
    const getText = useLanguage().getText;

    const resultBatchSize = 25;

    const [retrieving, setRetrieving] = useState(false);
    const [results, setResults] = useState([])
    const [targetResultCount, setTargetResultCount] = useState(resultBatchSize)
    const [searchParameters, setSearchParameters] = useState({
        query: '',
        sortDown: true,
        sortField: 'relevancy',
    })

    useEffect(() => {
        setResults([]);
    }, [searchParameters])

    useEffect(() => {
        if (results.length >= targetResultCount) {
            return;
        }

        if (searchParameters.query === '') {
            return;
        }

        if (results.length && results[results.length - 1].error) {
            return;
        }

        const defaultParameters = `count=${resultBatchSize}&suppressNavLinks=true&start=${results.length}`
        const sortSign = searchParameters.sortDown ? '-' : '+';
        const paramString = defaultParameters + `&query=${searchParameters.query}&sort=${sortSign}${searchParameters.sortField}`

        setRetrieving(true);

        axios.get(`/api/content/search/?${paramString}`, {
            Headers: {
                'Content-Type': 'application/json'
            },
            data: {
                'query': 'hydrogen'
            }
        })
            .then(function (response) {
                console.log(response.data);
                if (response.data['search-results']['opensearch:totalResults'] === '0') {
                    setResults(results.concat(response.data['search-results']['entry']));
                } else {
                    setResults(results.concat(response.data['search-results']['entry']));
                }
                setRetrieving(false);
            });
    }, [results, targetResultCount, searchParameters]);

    function onResultsScroll(e) {
        const newResultsCutoffPx = 100;

        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < newResultsCutoffPx) {
            setTargetResultCount(results.length + resultBatchSize);
        }
    }

    return (
        <div className="offcanvas-add-knowledge offcanvas offcanvas-start offcanvas-internal" tabIndex="-1" id={controlPanel.id} aria-labelledby="offcanvasScrollingLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasScrollingLabel">{getText('add-scientific-documents')}</h5>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <div className='d-flex flex-column h-100'>
                    <SearchForm searchParameters={searchParameters} setSearchParameters={setSearchParameters} />
                    <div className="overflow-auto flex-grow-1" onScroll={onResultsScroll}>
                        <div className="accordion" id="searchResultsAccordion">
                            {results.map((result) => (<SearchResult metadata={result} setGraphData={setGraphData} />))}
                            {retrieving ? (<SearchResultsSkeleton />) : (<></>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SearchForm({ searchParameters, setSearchParameters }) {
    const getText = useLanguage().getText;
    const queryInputRef = useRef(null);

    function handleChange(e) {
        setSearchParameters({
            ...searchParameters,
            [e.target.name]: e.target.value
        });
    }

    function onSubmit(e) {
        e.preventDefault();
        handleChange({ target: { name: 'query', value: queryInputRef.current.value } })
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="row mb-3">
                <div className="col-md">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder={getText('search...')} aria-label={getText('search...')} aria-describedby="button-addon2"
                            name='query' ref={queryInputRef} />
                        <button className="btn btn-outline-secondary" type="submit" id="button-addon2"><SearchIcon></SearchIcon></button>
                    </div>
                </div>
                <div className="col-md-auto d-flex">
                    <div className="input-group">
                        <select className="form-select" aria-label="Default select example" name='sortField' onChange={handleChange}>
                            <option value="relevancy">{getText('relevancy')}</option>
                            <option value="artnum">{getText('artnum')}</option>
                            <option value="citedby-count">{getText('citedby-count')}</option>
                            <option value="coverDate">{getText('coverDate')}</option>
                            <option value="creator">{getText('creator')}</option>
                            <option value="pagecount">{getText('pagecount')}</option>
                            <option value="pagefirst">{getText('pagefirst')}</option>
                            <option value="pageRange">{getText('pageRange')}</option>
                            <option value="publicationName">{getText('publicationName')}</option>
                            <option value="pubyear">{getText('pubyear')}</option>
                            <option value="volume">{getText('volume')}</option>
                        </select>
                        <button type="button" className="btn btn-outline-secondary"
                            onClick={() => { handleChange({ target: { name: 'sortDown', value: !searchParameters.sortDown } }) }}>
                            <i className={"bi bi-" + (searchParameters.sortDown ? "sort-down" : "sort-up")}></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

function SearchResultsSkeleton() {
    function SearchResultSkeleton() {
        return (
            <Skeleton variant="rounded" width={'100%'} height={60} sx={{ marginBottom: '10px' }} />
        )
    }

    return (
        <>
            <SearchResultSkeleton />
            <SearchResultSkeleton />
            <SearchResultSkeleton />
            <SearchResultSkeleton />
            <SearchResultSkeleton />
            <SearchResultSkeleton />
            <SearchResultSkeleton />
        </>
    )
}

function SearchResult({ metadata, setGraphData }) {
    const id = useId();
    const getText = useLanguage().getText;

    const [collapsed, setCollapsed] = useState(true);
    const [abstract, setAbstract] = useState(null);

    const [abstractButtonState, setAbstractButtonState] = useState('disabled');
    const [fullTextButtonState, setFullTextButtonState] = useState('disabled');

    useEffect(() => {
        if (collapsed || abstract != null) {
            return;
        }

        const scopus_id = metadata["dc:identifier"].split(':')[1];
        axios.get(`/api/content/article/scopus_id/${scopus_id}?view=META_ABS`)
            .then(function (response) {
                if (!response.data['full-text-retrieval-response']?.['coredata']?.['dc:description']) {
                    setAbstract(undefined);
                } else {
                    setAbstract(response.data['full-text-retrieval-response']['coredata']['dc:description']);
                    setAbstractButtonState('enabled');
                    setFullTextButtonState('enabled');
                }
            });
    }, [collapsed, abstract, metadata]);

    function sendAddDocumentRequest() {
        setAbstractButtonState('loading');

        axios.post(`/api/kg/add-document/`, {
            doi: metadata["prism:doi"],
            type: 'abstract',
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            setAbstractButtonState('completed');
            setGraphData(response.data);
        }).catch(function (error) {
            setAbstractButtonState('enabled');
        });
    }

    const error = metadata['error'];

    return (
        <>
            {error ? (<>{error}</>) : (
                <div className="accordion-item user-select-none">
                    <h2 className="accordion-header">
                        <button className="accordion-button collapsed" onClick={() => setCollapsed(!collapsed)} type="button" data-bs-toggle="collapse" style={{ outline: 'none', boxShadow: 'none' }} data-bs-target={'#' + id} aria-expanded="false" aria-controls="collapseOne">
                            <div className="col-10">
                                <h6>{error ? metadata['error'] : metadata["dc:title"]}</h6>
                                <p className="card-text">{metadata["prism:coverDisplayDate"]} - {metadata["dc:creator"]} &nbsp;&nbsp; Cited by {metadata["citedby-count"]} </p>
                            </div>
                        </button>
                    </h2>
                    <div id={id} className="accordion-collapse collapse" data-bs-parent="#searchResultsAccordion"  >
                        <div className="accordion-body">
                            {abstract === null ?
                                <Skeleton variant="rounded" width={'100%'} height={200} sx={{ marginBottom: '10px' }} />
                                : <p className="card-text">{
                                    abstract === undefined ?
                                        getText('text-not-available')
                                        : abstract
                                }</p>}

                            <div className="row">
                                <div className="col-md">
                                    <p className="card-text mb-0">
                                        <small className="text-muted">Published in <i>{metadata["prism:publicationName"]}</i></small>
                                    </p>
                                    <p className="card-text">
                                        <small className="text-muted">DOI: {metadata["prism:doi"]}</small>
                                    </p>
                                </div>
                                <div className="col-md d-flex justify-content-end">
                                    <AddSearchResultButton name="Abstract" buttonState={abstractButtonState} onClick={sendAddDocumentRequest} />
                                    <AddSearchResultButton name="Full Text" buttonState={fullTextButtonState} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

function AddSearchResultButton({ name, buttonState, onClick }) {
    const classes = {
        'disabled': "btn-outline-primary disabled",
        'enabled': "btn-outline-primary",
        'loading': 'btn-outline-primary disabled',
        'completed': "btn-success disabled",
    }

    const contents = {
        'disabled': <>Add {name} <ChevronRightIcon /></>,
        'enabled': <>Add {name} <ChevronRightIcon /></>,
        'loading': <>Adding {name} <CircularProgress size={13} /></>,
        'completed': <><b>{name} Added</b> <CheckCircleOutlineIcon /></>,
    }

    return (
        <button className={"btn add-knowledge-button " + classes[buttonState]} type="button" onClick={onClick}>
            {contents[buttonState]}
        </button>
    )
}

function QueryKnowledgeControlPanel({ controlPanel }) {
    const getText = useLanguage().getText;

    const queryInputRef = useRef(null);
    function queryGraph(e) {
        e.preventDefault();

        axios.get(`/api/kg/query-knowledge/?query=${queryInputRef.current.value}`)
            .then(function (response) {
                console.log(response);
            });
    }

    return (
        <div className="offcanvas offcanvas-start offcanvas-internal" style={{ width: '600px' }} tabIndex="-1" id={controlPanel.id} aria-labelledby="offcanvasScrollingLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasScrollingLabel">{getText('query-knowledge-graph')}</h5>
            </div>
            <div className="offcanvas-body">
                <form onSubmit={queryGraph}>
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder={getText('search...')} aria-label={getText('search...')} aria-describedby="button-addon2"
                            name='query' ref={queryInputRef} />
                        <button className="btn btn-outline-secondary" type="submit" id="button-addon2"><SearchIcon></SearchIcon></button>
                    </div>
                </form>
            </div>
        </div>
    )
}

function KnowledgeGraph({ graphData }) {
    const container = useRef(null);

    useEffect(() => {
        console.log(graphData);
        const nodes = new DataSet(graphData.nodes.map(node => {
            return { id: node, label: node }
        }));
        const edges = new DataSet(graphData.edges.map(edge => {
            return { from: edge[0], to: edge[1] }
        }));

        var data = {
            nodes: nodes,
            edges: edges,
        };
        var options = {};
        new Network(container.current, data, options);
    }, [container, graphData]);

    return (
        <div ref={container} className='' style={{ height: '100%', width: '100%', background: '', zIndex: 0, position: 'relative' }}></div>
    )
}
