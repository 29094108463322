import React, { createContext, useContext } from 'react';
import { useCookies } from 'react-cookie';

export const BackendContext = createContext();

export function BackendProvider({ children }) {
    const [cookies, setCookie] = useCookies(['backend']);

    if (!cookies['backend']) {
        setCookie('backend', 'pdm1', { path: '/', expires: new Date(Date.now() + 1 * 60 * 60 * 24 * 365) });
    }

    let value = {
        backend: cookies['backend'],
        setBackend: (code) => setCookie('backend', code, { path: '/', expires: new Date(Date.now() + 1 * 60 * 60 * 24 * 365) }),
        backends: ["pdm1", "pdm2"],
        getFormattedBackend: getFormattedBackend,
    }

    return <BackendContext.Provider value={value}>{children}</BackendContext.Provider>;

    function getFormattedBackend(code) {
        let formatted = {
            "pdm1": "PDM 1.0",
            "pdm2": "PDM 2.0",
        }
        return formatted[code];
    }
}

export function useBackend() {
    return useContext(BackendContext);
}
