import { useLanguage } from "../../context/Language"

export default function InfrastructureAi() {
    const getText = useLanguage().getText;

    return (<div className='full-height'>
        <div className="d-flex justify-content-center">
            <div className="m-5">
                🛠 {getText('infrastructure-ai-construction')} 🛠
            </div>
        </div>
    </div>)
}
