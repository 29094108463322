import { useEffect, useState } from "react";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

export default function BpsManual() {
	const [manualHtml, setManualHtml] = useState(null);

	const circularProgress = <>
		<div className='spacer-sm'></div>
		<div className="full-height-sm">
			<div className='h-100 d-flex align-items-center justify-content-center'>
				<div>
					<CircularProgress />
				</div>
			</div>
		</div>
	</>;

	axios.get('/api/bps/text').then((res) => {
		setManualHtml(res.data);
		console.log(res);
	}).catch((err) => {
		console.log(err);
	});

	useEffect(() => {
		const elementId = window.location.hash.replace('#', '');
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({
				block: 'center',
			});

			element.style.backgroundColor = 'yellow';
		}
	});

	return (
		<>
			<div className='spacer-sm'></div>
			<div className="full-height-sm">
				{manualHtml == null ? circularProgress :
					<div style={{ marginLeft: '50px', marginRight: '50px' }}>
						<div dangerouslySetInnerHTML={{ __html: manualHtml }} />
					</div>
				}
			</div>
		</>
	);
}
