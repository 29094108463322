import { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import { isExpired, decodeToken } from "react-jwt";

var refreshTimer = null;

export const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [username, setUsername] = useState(null);
    const [isAffexy, setIsAffexy] = useState(null);
    const [isBps, setIsBps] = useState(null);
    const [isPzu, setIsPzu] = useState(null);
    const [isEnea, setIsEnea] = useState(null);

    useEffect(() => {
        loadLocalAuthToken();
    });

    let value = {
        isAuthenticated: isAuthenticated,
        isAffexy: isAffexy,
        isBps: isBps,
        isPzu: isPzu,
        isEnea: isEnea,
        username: username,
        login: async (loginData) => {
            await login(loginData);
        },
        logout: () => {
            logout();
        },
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;

    function loadLocalAuthToken() {
        const slidingToken = localStorage.getItem("sliding_token");
        if (!slidingToken) {
            setIsAuthenticated(false);
            return;
        }

        if (!isExpired(slidingToken)) {
            setAxiosHeaders();
            setIsAuthenticated(true);
        }

        tryRefreshAuth();
    }

    function tryRefreshAuth() {
        const slidingToken = localStorage.getItem("sliding_token");
        if (slidingToken && !refreshIsExpired(slidingToken)) {
            axios.post('/api/token/refresh/',
                { token: slidingToken }, {
                headers:
                    { 'Content-Type': 'application/json' }
            })
                .then((response) => {
                    updateslidingToken(response.data.token)
                })
                .catch((error) => {
                    setIsAuthenticated(false);
                });
        } else {
            setIsAuthenticated(false);
        }
    }

    async function login(loginData) {
        const { data } = await
            axios.post('/api/token/',
                loginData, {
                headers:
                    { 'Content-Type': 'application/json' }
            });

        updateslidingToken(data.token);
    }

    function updateslidingToken(slidingToken) {
        if (refreshTimer) {
            clearTimeout(refreshTimer);
        }

        refreshTimer = setTimeout(() => {
            tryRefreshAuth();
        }, getRefreshTimeout(slidingToken));

        localStorage.setItem('sliding_token', slidingToken);
        setAxiosHeaders();
        setIsAuthenticated(true);
        axios.get('/api/users/current', {
            headers:
                { 'Content-Type': 'application/json' },
        }).then((response) => {
            setUsername(response.data.username)
            setIsAffexy(response.data.groups.includes('affexy'))
            setIsBps(response.data.groups.includes('bps'))
            setIsPzu(response.data.groups.includes('pzu'))
            setIsEnea(response.data.groups.includes('enea'))
        })
    }

    function setAxiosHeaders() {
        const slidingToken = localStorage.getItem('sliding_token');
        axios.defaults.headers.common['Authorization'] =
            `Bearer ${slidingToken}`;
    }

    function getRefreshTimeout(slidingToken) {
        const decodedToken = decodeToken(slidingToken);
        const expiresAt = decodedToken.exp - Date.now() / 1000.;
        const timeout = (expiresAt - 30) * 1000;
        return timeout;
    }

    function logout() {
        localStorage.clear();
        axios.defaults.headers.common['Authorization'] = null;
        setIsAuthenticated(false);
    }

    function refreshIsExpired(slidingToken) {
        const decodedToken = decodeToken(slidingToken);
        const refreshExpiration = new Date(0);
        refreshExpiration.setUTCSeconds(decodedToken.refresh_exp);
        return refreshExpiration.valueOf() < new Date().valueOf()
    }
}

export function useAuth() {
    return useContext(AuthContext);
}
