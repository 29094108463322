import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Fade from '@mui/material/Fade';
import { useLanguage } from '../../context/Language';
import { useBackend } from '../../context/Backend';
import { Accordion } from 'react-bootstrap';
import { CircularProgress, Box, Typography, Stack } from '@mui/material';

export default function ChatbotAi({application_name}) {
    const { language } = useLanguage();
    const { backend } = useBackend();
    const [chatHistory, setChatHistory] = useState([]);
    const [sources, setSources] = useState([]);
    const [controller, setController] = useState(new AbortController());
    const [sourcesActiveKey, setSourcesActiveKey] = useState(null);
    const [llmStatus, setLlmStatus] = useState({ "state": "unknown" });

    const vmUpdateTimeout = useRef();

    useEffect(() => {
        axios.get('/api/banking/chatbot').then((res) => {
            setChatHistory(res.data["chat_history"].map((message) =>
                ({ messageChunks: [{ "text": message.content }], isUser: message.role === 'user', isComplete: true })
            ));
            setSources(res.data["sources"]);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {

        function delayedUpdateVmStatus(delay_seconds) {
            vmUpdateTimeout.current = setTimeout(() => {
                updateVmStatus();
            }, delay_seconds * 1000);
        }

        function updateVmStatus() {

            axios.get('/api/vm').then((res) => {

                setLlmStatus(res.data);

                if (res.data["state"] === "ready") {
                    delayedUpdateVmStatus(60);
                    return
                }

                if (res.data["state"] === "stopped") {
                    startVm();
                    return
                }
    
                delayedUpdateVmStatus(5);
            });
        }

        function startVm() {
            setLlmStatus({ "state": "booting" });

            axios.post('/api/vm').then((res) => {
                if (res.status !== 200) {
                    alert(`Failed to start LLM. ${res.data["error"]}`);
                }
                delayedUpdateVmStatus(5);
            }).catch((err) => {
                alert(`Failed to start LLM. ${err}. ${err.response.data["error"]}`);
                delayedUpdateVmStatus(5);
            });
        }

        if (backend === "pdm1") {
            setLlmStatus({ "state": "ready" });
        } else if (backend === "pdm2") {
            setLlmStatus({ "state": "unknown" });

            if (vmUpdateTimeout.current) {
                clearTimeout(vmUpdateTimeout.current);
            }

            delayedUpdateVmStatus(1);
        }

        return () => clearTimeout(vmUpdateTimeout.current);
    }, [backend]);


    const onChatClear = () => {
        setChatHistory([]);
        setSourcesActiveKey(null);
        setSources([]);
        controller.abort();
        setController(new AbortController());
        axios.delete('/api/banking/chatbot');
    }

    const onChatSubmission = (newMessage) => {
        setSourcesActiveKey(null);

        const newChatHistory = [...chatHistory,
        { messageChunks: [{ "text": newMessage }], isUser: true, isComplete: true },
        ];

        setChatHistory([...newChatHistory,
        { messageChunks: [], isUser: false, isComplete: false },
        ]);

        const baseUrl = process.env.REACT_APP_BACKEND_URL;

        fetch(`${baseUrl}/api/banking/chatbot`, {
            method: 'POST',
            body: JSON.stringify({
                message: newMessage,
                language: language,
                source: backend,
                application_name: application_name,
            }),
            headers: {
                Authorization: `Bearer ${localStorage.getItem('sliding_token')}`,
                'Content-Type': 'application/json',
            },
            signal: controller.signal,
        }).then((response) => {
                if (!response.ok) {
                    response.json().then((data) => {
                        alert(`${data["error"]}`);
                    });
                    setChatHistory(newChatHistory);
                    return
                }
            
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                var chatResponseText = "";
                var chatResponseChunks = [{ "text": "" }];
                var thisSources = [];
                var thisCitedSources = [...sources];
                const referenceRegex = /\[uid:\s*(.*?)\s*\]/g;
                var text = "";

                reader.read().then(function pump({ done, value }) {
                    if (done) {
                        setChatHistory([...newChatHistory,
                        { messageChunks: chatResponseChunks, isUser: false, isComplete: true },
                        ]);
                        return;
                    }

                    const newText = decoder.decode(value);
                    text += newText;

                    const lines = text.split('\n');
                    var lastLine = lines[lines.length - 1];
                    const linesToProcess = lines.slice(0, lines.length - 1);

                    text = lastLine;

                    linesToProcess.forEach((line) => {
                        var data;
                        try {
                            data = JSON.parse(line);
                        } catch (e) {
                            console.log(e);
                        }

                        if (data["sources"]) {
                            thisSources = data["sources"];
                        }
                        if (data["token"]) {
                            chatResponseText += data["token"];
                        }
                    });

                    const referenceMatches = Array.from(chatResponseText.matchAll(referenceRegex));
                    chatResponseChunks[chatResponseChunks.length - 1]["text"] = chatResponseText;
                    var lastReference = null;

                    referenceMatches.forEach((match, index) => {
                        var citedSourceIndex = thisCitedSources.findIndex((source) => source.metadata.uid === match[1]);

                        if (citedSourceIndex === -1) {
                            const source = thisSources.find((source) => source.metadata.uid === match[1]);

                            if (!source) {
                                return;
                            }

                            thisCitedSources.push(source);
                            setSources(thisCitedSources);

                            citedSourceIndex = thisCitedSources.length - 1;
                        }

                        setSources(thisCitedSources);

                        var previousMatchIndex = undefined;
                        var nextMatchIndex = undefined;
                        const previousRef = referenceMatches[index - 1];
                        const nextRef = referenceMatches[index + 1];
                        if (previousRef) {
                            previousMatchIndex = previousRef.index + previousRef[0].length;
                        }
                        if (nextRef) {
                            nextMatchIndex = nextRef.index;
                        }

                        chatResponseChunks[chatResponseChunks.length - 1]["text"] = chatResponseText.slice(previousMatchIndex, match.index);
                        chatResponseChunks.push({ "reference": { "index": citedSourceIndex, "value": match[1] } });
                        chatResponseChunks.push({ "text": chatResponseText.slice(match.index + match[0].length, nextMatchIndex) });
                        lastReference = match;
                    });

                    if (lastReference) {
                        chatResponseText = chatResponseText.slice(lastReference.index + lastReference[0].length);
                        chatResponseChunks[chatResponseChunks.length - 1]["text"] = chatResponseText;
                    }

                    setChatHistory([...newChatHistory,
                    { messageChunks: chatResponseChunks, isUser: false, isComplete: false },
                    ]);

                    return reader.read().then(pump);
                });
            }).catch((err) => {
                console.log("got error");
                window.alert(err);
                console.log(err);
            });
    }

    return (
        <>
            <div className='spacer-sm'></div>
            <div className='full-height-sm'>
                <div className="row h-100 w-100">
                    <div className="col-sm-6 h-100">
                        <div className="d-flex flex-column h-100">
                            <ChatWindow llmStatus={llmStatus} chatHistory={chatHistory} sourcesActiveKey={sourcesActiveKey} setSourcesActiveKey={setSourcesActiveKey} />
                            <ChatSubmission onChatSubmission={onChatSubmission} onChatClear={onChatClear} chatHistory={chatHistory} />
                        </div>
                    </div>
                    <div className="col h-100">
                        <ReferenceTable sources={sources} sourcesActiveKey={sourcesActiveKey} setSourcesActiveKey={setSourcesActiveKey} />
                    </div>
                </div>
            </div>
        </>
    );
}

function ChatWindow({ chatHistory, sourcesActiveKey, setSourcesActiveKey, llmStatus }) {
    return <div className="card m-3 me-0 shadow overflow-hidden max-height-sm" style={{ minHeight: "85%" }}>
        {llmStatus["state"] === "ready" ? <>
            {
                chatHistory.length > 0 ?
                    <ChatHistory chatHistory={chatHistory} sourcesActiveKey={sourcesActiveKey} setSourcesActiveKey={setSourcesActiveKey} /> :
                    <ChatHelpText />
            }
        </> :
            <ProgressPane llmStatus={llmStatus} />
        }
    </div>
}

function ProgressPane({ llmStatus }) {
    const { getText } = useLanguage();

    const [variant, setVariant] = useState("indeterminate");
    const [progress, setProgress] = useState(0);
    const [eta, setEta] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (llmStatus["state"] === "starting") {
            setVariant("determinate");
            setProgress(llmStatus["progress_percent"]);

            const eta_minute_string = (Math.floor(llmStatus["eta"] / 60)).toFixed(0).padStart(2, "0");
            const eta_second_string = (llmStatus["eta"] % 60).toFixed(0).padStart(2, "0");
            setEta(`(${eta_minute_string}:${eta_second_string})`);
        } else {
            setVariant("indeterminate");
            setProgress(0);
            setEta("");
        }

        setMessage(getText(`vm-${llmStatus["state"]}`));
    }, [llmStatus, getText]);

    return (
        <div className='h-100 d-flex align-items-center justify-content-center'>
            <div>
                <Stack spacing={2} sx={{alignItems:'center'}}>
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress variant={variant} value={progress} />
                        {
                            variant === "determinate" &&
                            <Box
                                sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    component="div"
                                    color="text.secondary"
                                >{`${Math.round(progress)}%`}
                                </Typography>
                            </Box>
                        }
                    </Box>
                    <Typography variant="h6" gutterBottom>
                        {message} {eta}
                    </Typography>
                </Stack>
            </div>
        </div>
    )
}

function ChatHistory({ chatHistory, sourcesActiveKey, setSourcesActiveKey }) {
    const endOfMessages = useRef();

    useEffect(() => {
        if (endOfMessages.current)
            endOfMessages.current.scrollIntoView({ behavior: 'smooth' });
    }, [chatHistory]);

    return <>
        <div className="overflow-auto">
            <ul className="list-group list-group-flush" >
                {chatHistory.map((message, index) => {
                    return (
                        <li className="list-group-item" key={index}>
                            <Fade in={true}>
                                <div className='d-flex'>
                                    <div className='me-2'>
                                        <i className={`bi ${message.isUser ? 'bi-person-circle' : 'bi-robot'}`}></i>
                                    </div>
                                    <div className='flex-grow-1' style={{ "whiteSpace": "pre-line" }}>
                                        {
                                            (true || message.isComplete) ?
                                                <>
                                                    {message.messageChunks.map((chunk) => {
                                                        if (chunk["text"]) {
                                                            return <>{chunk["text"]}</>;
                                                        }

                                                        if (chunk["reference"]) {
                                                            const ref = chunk["reference"];
                                                            return <ReferenceButton sourceKey={ref["value"]} index={ref["index"]} sourcesActiveKey={sourcesActiveKey} setSourcesActiveKey={setSourcesActiveKey} />;
                                                        }

                                                        return <></>;
                                                    })}
                                                    {!message.isComplete &&
                                                        <div className='d-flex justify-content-center mt-2 mb-2'>
                                                            <Skeleton variant="circular" width="1em" height="1em" className='me-2' style={{ animationDelay: "0ms" }} />
                                                            <Skeleton variant="circular" width="1em" height="1em" className='me-2' style={{ animationDelay: "400ms" }} />
                                                            <Skeleton variant="circular" width="1em" height="1em" className='me-2' style={{ animationDelay: "800ms" }} />
                                                        </div>
                                                    }
                                                </>
                                                : <Skeleton variant="rounded" width={'100%'} height={60} sx={{ marginBottom: '10px' }} />
                                        }
                                    </div>
                                </div>
                            </Fade>
                        </li>
                    );
                })}
                <li className="list-group-item" ref={endOfMessages}></li>
            </ul>
        </div>
    </>
}

function ChatHelpText() {
    const { getText } = useLanguage();

    return <>
        <div className='h-100 d-flex align-items-center justify-content-center'>
            <div>
                <i className="bi bi-arrow-down"></i> {getText('question-redirection')} <i className="bi bi-arrow-down"></i>
            </div>
        </div>
    </>
}

function ReferenceButton({ sourceKey, index, sourcesActiveKey, setSourcesActiveKey }) {
    function onClick() {
        if (sourcesActiveKey === sourceKey) {
            setSourcesActiveKey(null);
        } else {
            setSourcesActiveKey(sourceKey);
        }
    }

    return (<button className='button-like-link' onClick={onClick}>{"[" + (index + 1) + "]"}</button>);
}

function ChatSubmission({ onChatSubmission, onChatClear, chatHistory }) {
    const { getText } = useLanguage();
    const messageRef = useRef();

    function onSubmit(e) {
        e.preventDefault();
        const newMessage = messageRef.current.value;
        messageRef.current.value = '';

        onChatSubmission(newMessage);
    }

    function onClear(e) {
        e.preventDefault();
        onChatClear();
    }

    return <div>
        <div className="card m-3 me-0 mb-0 border-0">
            <div className='d-flex'>
                <form className='flex-grow-1' onSubmit={onSubmit}>
                    <div className="input-group mb-3 shadow rounded">
                        <input type="text" className="form-control" placeholder={getText('ask-a-question')} aria-describedby="button-addon2" ref={messageRef} />
                        <button className="btn btn-outline-secondary" type="submit" id="button-addon2" disabled={chatHistory.length > 0 && !chatHistory[chatHistory.length - 1].isComplete}><i className="bi bi-send"></i></button>
                    </div>
                </form>
                <form onSubmit={onClear}>
                    <button className="btn btn-outline-danger shadow rounded ms-3" type="submit" id="button-addon2"><i className="bi bi-trash3"></i></button>
                </form>
            </div>
        </div>
    </div>
}

function ReferenceTable({ sources, sourcesActiveKey, setSourcesActiveKey }) {
    const { getText } = useLanguage();

    const sourcesLoaded = sources.length > 0;

    return <div className="d-flex flex-column pt-2 h-100">
        <div className='ms-3'>
            <h4>{getText('reference-data')}</h4>
        </div>
        <div className="overflow-auto">
            <Accordion activeKey={sourcesActiveKey} className='ms-3' style={{ outline: 'none', boxShadow: 'none' }} >
                {
                    sourcesLoaded &&
                    sources.map((data, index) => {
                        return (
                            <SourceAccordion index={index} data={data} sourcesActiveKey={sourcesActiveKey} setSourcesActiveKey={setSourcesActiveKey} />
                        )
                    })
                }
                {
                    !sourcesLoaded &&
                    <div className='h-100 d-flex align-items-center justify-content-center'> {getText("sources-will-appear")} </div>
                }
            </Accordion>
        </div>
    </div>
}

function SourceAccordion({ index, data, sourcesActiveKey, setSourcesActiveKey }) {
    const { getText } = useLanguage();

    const metadata = data.metadata;
    const text = data.text;

    const paragraph_number = metadata.paragraph_number ? getText("paragraph") + ' ' + metadata.paragraph_number : null;
    const documentReference = [metadata.part, metadata.title, metadata.chapter, metadata.section, metadata.article, paragraph_number].filter(x => !!x).join(' - ');

    const activeKey = data.metadata.uid;

    const handleClick = () => {
        if (sourcesActiveKey === activeKey) {
            setSourcesActiveKey(null);
        } else {
            setSourcesActiveKey(activeKey);
        }
    }

    let titleString = "";
    if (metadata.regulation) {
        titleString = metadata.regulation;
    } else {
        titleString = metadata.subsection;

        if (metadata.subsubsection) {
            titleString += " - " + metadata.subsubsection;
        }
    }

    return <>
        <Fade in={true}>
            <Accordion.Item eventKey={activeKey}>
                <Accordion.Button onClick={handleClick} style={{ outline: 'none', boxShadow: 'none' }} >
                    <div>
                        <h4><span className="me-4">{index + 1}</span></h4>
                    </div>
                    <div>
                        <h6>{titleString}</h6>
                        <p className="card-text">{metadata.topic}</p>
                        <p className="card-text">{metadata.date} &nbsp;&nbsp; {documentReference}</p>
                    </div>
                </Accordion.Button>
                <Accordion.Body>
                    <p className="card-text" style={{ "whiteSpace": "pre-line" }}>{text}</p>
                    <div className="row">
                        <div className="col-md">
                            <p className="card-text mb-0">
                                <small className="text-muted">{getText('document-new-tab')} <a target="_blank" rel="noreferrer" href={metadata.url + metadata.closest_id}>{getText('here')}</a>.  </small>
                            </p>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Fade>
    </>
}
